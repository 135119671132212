import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import InvoiceForm, { valid as verifyInvoice } from "../Components/InvoiceForm";
import * as Cart from "../Contexts/CartContext";
import { isEnt } from "../Domain/RoleValidator";
import * as Theme from "../Theme";
import { isNotEmpty, isEmail, isMobileNumber } from "../Domain/FormatValidator";
import * as Ant from "antd";
import {
  SectionHeader,
  FieldRow,
  Label,
  Value,
  TextInput,
  Select,
  SelectOption,
} from "../Components/Order.Widget";
import { navigate } from "gatsby";

class DepositCreditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      doVerifyInvoice: false,
      loading: false,
      credits: 3000,
      customizeCredits: 0,
      feedbackData: {
        limit: null,
        value: null,
        expire_date: null,
      },
      config: {
        invoiceConfig: {
          invoice_type: "two_copies",
          invoice_subtype: "ezpay_vehicle",
          mobile_vehicle_code: "",
          citizen_personal_certificate_code: "",
          company_title: "",
          gui_number: "",
          donate_foundation_gui: "",
          love_code: "",
        },
        userConfig: {
          invalid: false,
          name: "",
          phone: "",
          email: "",
          zip: "",
          tel: "",
          tel_ext: "",
          address: "",
        },
      },
      checkHint1: false,
    };
  }

  async componentDidMount() {
    this.setState({ loading: true });
    this._autofill();
    this._getFeedBackRule();

    this.setState({ loading: false });
  }
  render() {
    let {
      loading,
      credits,
      customizeCredits,
      feedbackData,
      config,
      doVerifyInvoice,
    } = this.state;
    let { profile } = this.props;

    let { finalBuyCredit, feekbackCredit, totalCredit } = this._estimate();

    return (
      <Wrapper>
        {config.userConfig.invalid && (
          <Ant.Alert
            message="為保證發票資料正確，請至個人資料管理 > 基本資料，填入正確的會員資料，才能儲值點數。"
            type="warning"
            style={{ marginBottom: 10 }}
          />
        )}
        <DataBlock>
          <p>帳戶剩餘加值點數</p>
          <p style={{ color: Theme.colors.brown }}>{profile.credits}點</p>
        </DataBlock>
        <SectionHeader>選擇加值面額</SectionHeader>
        <Section>
          <FieldRow>
            <Label>加值點數費用</Label>
            <Select
              value={credits}
              onChange={value => this.setState({ credits: parseInt(value) })}
            >
              <SelectOption value={3000}>3000元</SelectOption>
              <SelectOption value={5000}>5000元</SelectOption>
              <SelectOption value={10000}>10000元</SelectOption>
              <SelectOption value={20000}>20000元</SelectOption>
              <SelectOption value={30000}>30000元</SelectOption>
              <SelectOption value={50000}>50000元</SelectOption>
              <SelectOption value={100000}>100000元</SelectOption>
              <SelectOption value={0}>自訂點數</SelectOption>
            </Select>
          </FieldRow>

          {credits === 0 && (
            <FieldRow>
              <Label>自訂點數</Label>
              <TextInput
                type="number"
                placeholder="需大於100"
                value={customizeCredits}
                onChange={e =>
                  this.setState({
                    customizeCredits: e.target.value,
                  })
                }
              />
            </FieldRow>
          )}

          <FieldRow>
            <Label>加值點數：</Label>
            <Value>{finalBuyCredit}點</Value>
          </FieldRow>

          {/* 點數贈送優惠 */}
          {!(customizeCredits !== 0 && customizeCredits < 3000) && (
            <>
              <FieldRow style={{ marginTop: 10 }}>
                <Label>加值優惠(預繳)：</Label>
                <Value color={Theme.colors.main} type="long">
                  點數儲值即贈送 {feedbackData.value * 100}{" "}
                  %點數回饋，最多可回饋{feedbackData.limit}點
                </Value>
              </FieldRow>
              <FieldRow style={{ marginTop: 10 }}>
                <Label>贈送點數：</Label>
                <Value>{feekbackCredit}點</Value>
              </FieldRow>
            </>
          )}

          <FieldRow style={{ marginTop: 10 }}>
            <Label>加值後帳戶總點數：</Label>
            <Value color={Theme.colors.main}>{totalCredit}點</Value>
          </FieldRow>
        </Section>
        <SectionHeader>發票資料</SectionHeader>
        <Section>
          {config.invoiceConfig && (
            <InvoiceForm
              doValidate={doVerifyInvoice}
              toCheckHint={({ checkHint1 }) => this.setState({ checkHint1 })}
              config={config.invoiceConfig}
              setConfig={invoice_config => {
                this.setState({
                  doVerifyInvoice: false,
                  config: {
                    ...config,
                    invoiceConfig: invoice_config,
                  },
                });
              }}
            />
          )}
        </Section>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            padding: 20,
          }}
        >
          <Button loading={loading} onClick={this._submit}>
            確認加值點數成立訂單
          </Button>
        </div>
      </Wrapper>
    );
  }

  _autofill = () => {
    let { cart, profile } = this.props;
    let { userConfig, invoiceConfig } = this.state.config;

    if (cart.data) {
      const config = { ...cart.data.config };

      //user
      userConfig = {
        name: config.name,
        phone: config.phone,
        email: config.email,
        tel: config.tel,
        tel_ext: config.tel_ext,
        zip: config.zip,
        address: config.address,
      };

      //invoice
      if (config.invoiceConfig) {
        invoiceConfig = {
          ...config.invoiceConfig,
        };
      }
    }

    if (profile) {
      userConfig.name = userConfig.name || profile.name;
      userConfig.phone = userConfig.phone || profile.phone;
      userConfig.email = userConfig.email || profile.email;
      userConfig.address = userConfig.address || profile.address;
    }

    this.setState({ config: { userConfig, invoiceConfig } }, () => {
      this._checkUserValid();
    });
  };

  // 計算加值後點數
  _estimate = () => {
    let { credits, customizeCredits, feedbackData } = this.state;
    let { profile } = this.props;
    let _credits = credits || customizeCredits;

    let result = {
      finalBuyCredit: 0, //加值點數
      feekbackCredit: 0, //贈送點數
      totalCredit: 0, /// 加值後帳戶總點數
    };

    result["finalBuyCredit"] = Number(_credits).toFixed();

    if (_credits >= 3000 && feedbackData.value) {
      // 最低買 3000才有回饋
      result["feekbackCredit"] =
        _credits * feedbackData.value > feedbackData.limit
          ? feedbackData.limit.toFixed()
          : (_credits * feedbackData.value).toFixed();
    }

    result["totalCredit"] = (
      Number(profile.credits) +
      Number(result.feekbackCredit) +
      Number(result.finalBuyCredit)
    ).toFixed();

    return result;
  };

  // 取得可獲得優惠規則
  _getFeedBackRule = async () => {
    let { profile } = this.props;

    try {
      let feedbackData = await Cart.Actions.getCurrentFeedbackPromotionByUserType(
        {
          user_group: profile.group,
        }
      );

      // if (isEnt(profile)) {
      //   feedback_data = await Cart.Actions.getCurrentFeedbackPromotionByUserType(
      //     "ent_vip"
      //   );
      // } else {
      //   feedback_data = await Cart.Actions.getCurrentFeedbackPromotionByUserType(
      //     "vip"
      //   );
      // }
      this.setState({ feedbackData });
    } catch (ex) {
      console.warn(ex);
    }
  };

  _submit = async () => {
    let { appActions } = this.props;
    let { credits, customizeCredits, config, checkHint1 } = this.state;
    if (credits == 0) {
      if (customizeCredits < 100) {
        return Ant.message.error("自訂點數不得小於100");
      }
      credits = customizeCredits;
    }

    if (!checkHint1) {
      return Ant.message.error("請閱讀且同意注意事項");
    }

    this.setState({ doVerifyInvoice: true });

    if (verifyInvoice(config.invoiceConfig) && this._checkUserValid()) {
      try {
        this.setState({ loading: true });
        await Cart.Actions.editConfig(config);
        let order = await Cart.Actions.createCreditsOrder(credits);
        navigate(`/order?id=${order.id}`);
      } catch (ex) {
        console.warn(ex);
      }
      this.setState({ loading: false });
    }
  };

  _checkUserValid = () => {
    let { userConfig } = this.state.config;
    let { name, phone, email } = userConfig;
    console.log("check user", name, phone, email);
    // user config
    if (!isNotEmpty(name) || !isNotEmpty(email) || !isNotEmpty(phone)) {
      Ant.message.error("會員資料名稱、信箱、手機為必填");
      this.setState({
        config: {
          ...this.state.config,
          userConfig: { ...userConfig, invalid: true },
        },
      });
      return false;
    }
    if (!isEmail(email)) {
      Ant.message.error("會員資料電子信箱格式錯誤");
      this.setState({
        config: {
          ...this.state.config,
          userConfig: { ...userConfig, invalid: true },
        },
      });
      return false;
    }
    if (!isMobileNumber(phone)) {
      Ant.message.error("會員資料手機格式錯誤");
      this.setState({
        config: {
          ...this.state.config,
          userConfig: { ...userConfig, invalid: true },
        },
      });
      return false;
    }

    return true;
  };
}

const Wrapper = styled.div``;
const Section = styled.div`
  padding: ${Theme.rwdPadding.outer};
  @media screen and (max-width: ${Theme.breakpoints.xs}px) {
    padding: 0px;
  }
`;
const DataBlock = styled.div`
  background-color: ${Theme.colors.sub};
  padding: ${Theme.rwdPadding.outer};
  margin: ${Theme.rwdPadding.outer} 0px;
  text-align: center;
  & > p {
    color: ${Theme.colors.main};
    font-size: 16px;
    margin-bottom: 5px;
  }
`;
const Button = styled(Ant.Button).attrs({
  type: "primary",
})`
  flex-grow: 0;
  background-color: ${Theme.colors.lightGreen};
  color: ${Theme.colors.text};
  border: 0;
  padding: 7px 40px;
  height: auto;
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(Cart.withConsumer(DepositCreditForm));
