import React, { useState, useCallback, useContext, useEffect } from "react";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import styled from "styled-components";
import useDimensions from "../hooks/use-dimension";
import { Table } from "antd";
import { Context } from "../AppContext";
import Image from "./Image";
import * as Theme from "../Theme";
import timeUtil from "../Utils/TimeFormat";

const PAGINATION = {
  limit: 10,
  current: 1,
};

const UISTATE = {
  credits: "credits",
  bonus: "bonus",
};

function parseNote(record) {
  let result = "";
  if (record.order_display_id) {
    result = `訂單編號 ${record.order_display_id}`;
  }
  if (record && record.value) {
    if (record.value.feedback) {
      result += ` 回饋 ${record.value.feedback}`;
    }
    if (record.value.note) {
      result +=
        {
          bonus_gift: "撒紅利",
          dashboard: "後台操作",
        }[record.value.note] || record.value.note;
    }
  }
  return result;
}

const columns = {
  bonus: [
    {
      title: "日期",
      dataIndex: "created",
      key: "created",
      render: created => timeUtil.format(created, "YYYY-MM-DD HH:mm"),
      responsive: ["sm"],
    },
    {
      title: "原紅利金",
      key: "_bonus",
      render: record => record.value._bonus || "---",
      responsive: ["sm"],
    },
    {
      title: "差額",
      key: "diff",
      render: record => {
        if (!record.value.diff) {
          return record.value.bonus - record.value._bonus;
        } else {
          return (
            `${record.value.diff || ""} ${
              record.value.feedback ? "+" + record.value.feedback : ""
            }`.trim() || "---"
          );
        }
      },
      responsive: ["sm"],
    },
    {
      title: "剩餘紅利金",
      key: "bonus",
      render: record => record.value.bonus || "---",
      responsive: ["sm"],
    },
    {
      title: "備註",
      key: "note",
      render: record => parseNote(record),
      responsive: ["sm"],
    },
    {
      title: "紀錄",
      key: "id",
      className: "rwd-mobile",
      render: record => {
        const diff = !record.value.diff
          ? record.value.bonus - record.value._bonus
          : `${record.value.diff || ""} ${
              record.value.feedback ? "+" + record.value.feedback : ""
            }`.trim() || "---";

        return (
          <div>
            <div className="row" key="created">
              <div className="label">日期</div>
              <div className="text">
                {timeUtil.format(record.created, "YYYY-MM-DD HH:mm")}
              </div>
            </div>
            <div className="row" key="_bonus">
              <div className="label">原紅利</div>
              <div className="text">{record.value._bonus || "---"}</div>
            </div>
            <div className="row" key="diff">
              <div className="label">差額</div>
              <div className="text">{diff}</div>
            </div>
            <div className="row" key="bonus">
              <div className="label">剩餘紅利</div>
              <div className="text">{record.value.bonus || "---"}</div>
            </div>
            <div className="row" key="note">
              <div className="label">備註</div>
              <div className="text">{parseNote(record)}</div>
            </div>
          </div>
        );
      },
    },
  ],
  credits: [
    {
      title: "日期",
      dataIndex: "created",
      key: "created",
      render: created => timeUtil.format(created, "YYYY-MM-DD HH:mm"),
      responsive: ["sm"],
    },
    {
      title: "原點數",
      key: "_credits",
      render: record => record.value._credits || "---",
      responsive: ["sm"],
    },
    {
      title: "差額",
      key: "diff",
      render: record => {
        if (!record.value.diff) {
          return record.value.credits - record.value._credits;
        } else {
          return (
            `${record.value.diff || ""} ${
              record.value.feedback ? "+" + record.value.feedback : ""
            }`.trim() || "---"
          );
        }
      },
      responsive: ["sm"],
    },
    {
      title: "剩餘點數",
      key: "credits",
      render: record => record.value.credits || "---",
      responsive: ["sm"],
    },
    {
      title: "備註",
      key: "note",
      render: record => parseNote(record),
      responsive: ["sm"],
    },

    {
      title: "紀錄",
      key: "id",
      className: "rwd-mobile",
      render: record => {
        const diff = !record.value.diff
          ? record.value.credits - record.value._credits
          : `${record.value.diff || ""} ${
              record.value.feedback ? "+" + record.value.feedback : ""
            }`.trim() || "---";

        return (
          <div>
            <div className="row" key="created">
              <div className="label">日期</div>
              <div className="text">
                {timeUtil.format(record.created, "YYYY-MM-DD HH:mm")}
              </div>
            </div>
            <div className="row" key="_credits">
              <div className="label">原點數</div>
              <div className="text">{record.value._credits || "---"}</div>
            </div>
            <div className="row" key="diff">
              <div className="label">差額</div>
              <div className="text">{diff}</div>
            </div>
            <div className="row" key="credits">
              <div className="label">剩餘點數</div>
              <div className="text">{record.value.credits || "---"}</div>
            </div>
            <div className="row" key="note">
              <div className="label">備註</div>
              <div className="text">{parseNote(record)}</div>
            </div>
          </div>
        );
      },
    },
  ],
};

function CreditHistoryTable(props) {
  const app = useContext(Context);
  const { type, search, profile } = props;
  const { loading } = app.state;
  const [records, setRecords] = useState([]);
  const [filters, setFilters] = useState({
    ...PAGINATION,
  });
  const [total, setTotal] = useState(0);

  function filtersChange(obj) {
    setFilters(prev => ({ ...prev, ...obj }));
  }

  const { dimension } = useDimensions();

  const [jwtToken, setJwtToken] = useState();

  const getRecords = useCallback(async () => {
    app.actions.setLoading(true);

    if (jwtToken) {
      try {
        let resp = await app.actions.getHistories(
          {
            query: {
              instance_id: profile.id,
              model: "UserProfile",
              ...(type === UISTATE.bonus && {
                "value.bonus": { $exists: true },
              }),
              ...(type === UISTATE.credits && {
                "value.credits": { $exists: true },
              }),
            },
            paging: {
              limit: filters.limit,
              offset: (filters.current - 1) * filters.limit,
            },
            sorting: ["-created"],
          },
          jwtToken
        );

        const { results, total } = resp;

        setRecords(results);
        setTotal(total);
      } catch (ex) {
        console.warn(ex);
      }
    }

    app.actions.setLoading(false);
  }, [profile, filters, jwtToken, type]);

  useEffect(() => {
    (async () => {
      let { token } = await app.actions.getJwtToken();
      setJwtToken(token);
    })();
  }, []);

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  return (
    <Wrapper>
      <Table
        loading={loading}
        columns={columns[type]}
        rowKey="id"
        dataSource={records}
        onChange={pagination => filtersChange(pagination)}
        pagination={{
          total,
          pageSize: filters.limit,
          current: filters.current,
        }}
        // locale={{
        //   emptyText: (
        //     <Image
        //       src="/images/empty-order.svg"
        //       height="80"
        //       width="80"
        //       alt="empty history"
        //     />
        //   ),
        // }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .ant-table .ant-table-cell.rwd-mobile {
    & .row {
      display: flex;
      align-items: center;

      & > .label {
        flex-basis: 120px;
      }

      & > .text {
        color: grey;
      }
    }

    @media screen and (min-width: ${Theme.breakpoints.xs}px) {
      display: none;
    }
  }
`;

export default connect(
  (state, ownProps) => ({
    profile: Selectors.getLoginUser(state),
  }),
  null
)(CreditHistoryTable);
